import React from 'react';
import axios from 'axios';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import 'primereact/resources/primereact.css';
import address from '../address.component'
import logo from '../../assets/embassy_logo.png';
import './login.styles.css'

class LogIn extends React.Component {
  constructor(props) {
      super(props)
      this.state = {
        users:[],
        username:'',
        password:'',
      }
  }
  generateString(target) { // displays the string, replaces all but the last character with "*"
    let string = ''
    for (let i =0; i < target.length;i++) {
        if (i === target.length - 1) {
            string += target[i]
        } else {
            string += '*'
        }
    }
    return string
  }


  LogIn() { // call authentication api
    if (this.state.username.length === 0) {
      this.toast.show({severity:'error', summary: 'Error : No Username Entered', life: 3000});
      return
    }
    if (this.state.password.length === 0) {
      this.toast.show({severity:'error', summary: 'Error : No Password Entered', life: 3000});
      return
    }
  axios.post(address+'/item/login',{
    user:this.state.username,
    password:this.state.password
  }).then(
    res => {
      let data = res.data
      if (data === 'Success') {
        this.props.callback({expiryDate:new Date().getTime() + 86400000})
        console.log('in here')
      } else {
        this.toast.show({severity:'error', summary: 'Error : Incorrect PIN', life: 3000});
        this.setState({input:''})
      }
      console.log(res.data)
  }).catch(err=>console.log(err))
}

  render() {
    return <>
    <Toast ref={(el) => this.toast = el} position="top-center"/>     
    <div style={{
      left:'40.5vw',
      top:'28.75vh',
      position:'absolute',
      border:'4px solid #cccccc',
      borderRadius:'.5em',
      padding:'2em',
      backgroundColor:'white',

    }}>

      <div style={{
        width:'15vw',
        height:'32.5vh',
        float:'left',
        
      }}>
        <div style={{height:'10vh'}}>
          <img src={logo} style={{width:'15vw',float:'left'}}></img>
          <h1 style={{clear:'right',lineHeight:'7.125vh',fontFamily: '"Copperplate", sans-serif',fontWeight:'600',fontSize:'2.5em'}}>Scheduling App</h1>

        </div>
        <div style={{display:'inline-grid',gridTemplateColumns:'auto',rowGap:'1.5vh', marginTop:'5vh'}}>
        <InputText value={this.state.username} placeholder={'Enter Username'} onChange={(e) => this.setState({username:e.target.value})} />
        <InputText value={this.generateString(this.state.password)} placeholder={'Enter Password'} onChange={(e) => {
            if (e.nativeEvent.inputType === "insertText") {
              if (e.nativeEvent.data) {
                this.setState({password:this.state.password + e.nativeEvent.data})
              }
            } else {
              this.setState({password:this.state.password.slice(0,this.state.password.length-1)})
            }
            console.log(this.state.password)
            }}/>
        <h2 onClick={()=>this.LogIn()}style={{textAlign:'center',width:'10vw',height:'5vh',backgroundColor:'blue',color:'white',lineHeight:'5vh'}}> Log In</h2>
        </div>
        

      </div>

    </div>
    </>
  }

}

export default LogIn