import React from 'react';
import { Layout} from 'antd';
import Page from '../components/LogIn/login.component';
import background from '../assets/login_bg.png'
const HomePage = (props)=>(
    
    <Layout style={{ minHeight: '100vh' }}>

    <Layout>

    <Layout className = 'site-layout'>
    <div
      className='site-layout-background' style={{
        minHeight: '100vh',
        position: 'relative',
        textAlign: 'center',
      }}
    >
      <img src={background} style={{width:"100vw",height:'100vh',opacity:'.4'}}></img>
      <Page callback={userObject=>props.callback(userObject)}/>

    </div> 
    </Layout>
    </Layout>
    </Layout>
);




export default HomePage;