import React from 'react';
import './App.css';
import {Switch, Route} from 'react-router-dom';
import MainPage from './pages/main.component';
import LoginPage from './pages/login.component';

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { EMB_LOG_IN } from '../src/redux2/types';
import { store } from '../src/redux2/store';
import axios from 'axios';
import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
    embUser: state.embUser,
});

function EMBLogin(object) {
  console.log(object,'here i am!')
  store.dispatch({
    type:EMB_LOG_IN,
    embUser:object
  })
}

function App({ embUser }) {
  console.log(embUser)
    if (embUser && new Date(embUser.expiryDate).getTime() > new Date().getTime()) {
      
    } else {
      return <LoginPage callback={object=>EMBLogin(object)}/>
    }
  return (
    <div>
      <Switch>
      <Route exact path= '/' component={MainPage}/>
      </Switch>
      
    </div>
  );
}

export default connect(mapStateToProps)(App);
