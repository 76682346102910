export const CONNECT_WITH_BAD_CREDENTIALS = 'connect-with-bad-credentials';
export const CONNECT_WITH_SSO = 'connect-with-sso';
export const DISCONNECT = 'disconnect';
export const SHOW_SECRET = 'show-secret';
export const EMB_LOG_IN = 'emb-log-in';
export const EMB_LOG_OUT = 'emb-log-out';
export interface User {
    domain: string;
    name: string;
    displayName: string;
    groups: string[];
    sid: string;
    adUser?: any;
}
export interface embUser {
  expiryDate: string;
}

interface ConnectWithSSOAction {
    type: typeof CONNECT_WITH_SSO;
    user: User;
}

interface ConnectWithCredentialsAction {
    type: typeof CONNECT_WITH_BAD_CREDENTIALS;
}

interface ShowSecretAction {
    type: typeof SHOW_SECRET;
    secret: string;
}

interface DisconnectAction {
    type: typeof DISCONNECT;
}

interface EMB_LOG_IN {
  type: typeof EMB_LOG_IN;
  embUser: embUser;
}

interface EMB_LOG_OUT {
  type: typeof EMB_LOG_OUT;
}

export type AppAction =
    | ConnectWithSSOAction
    | ConnectWithCredentialsAction
    | EMB_LOG_IN
    | EMB_LOG_OUT
    | ShowSecretAction
    | DisconnectAction;


export interface UserState {
    user?: User;
}

export interface SecretState {
    secret: string;
}

export interface EMBUserState {
  embUser?: embUser;
}

export interface ErrorState {
    error: boolean;
}

export interface AppState extends EMBUserState,UserState, SecretState, ErrorState { }

export interface ConnectDispatch {
    disconnect: () => void;
}
