import React from 'react';
import { Layout} from 'antd';
import Header from '../components/header/header.component';
import FooterComponent from '../components/footer/footer.component';
import Dashboard from '../components/main/catalog.component';
import address from '../components/address.component'

const { Content} = Layout;
class HomePage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      date:[],
      numofdays:5,
      type:'Flavour',
      download:false,
      tab:'Unscheduled',
      synced:0
    }
  }
  
  updateDate = data => {
    console.log(data)
    if (data[1] === null) {

    
    } else { // calculate difference in days
      this.setState({date:this.GenerateDateArray(data[0],data[1])})
    }
  }

  componentDidMount() {
    let startDate = new Date()
    let endDate = new Date((new Date(startDate)).setDate(startDate.getDate()+6))
    this.updateDate([startDate,endDate])
  }

  updateType = data => {
    this.setState({type:data.name})
  }

  updateUnscheduledStandard = data => { // unscheduled / standard swap
    this.setState({tab:data.name})

  }
  GenerateDateArray(startDate,endDate) {
    
    let array = []
    for (let i = 0; i < this.state.numofdays;i++) {
      array.push(this.processDateArray(i,startDate))
    }
    if (this.state.numofdays === 7) {
      //array.push(array[0])
      //array.splice(0,1)
    }
    console.log(array)
    return array
  }

  userSynced = data => {
    this.setState({synced:this.state.synced+1})
  }

  processDateArray(index,startDate) {
    let baseDate = new Date((new Date(startDate)).setDate(startDate.getDate()+index))
    let month = baseDate.getMonth()
    let day = baseDate.getDay() //shorthand for Day of Week
    switch (day) {
      case 0:
        day = "Sunday";
        break;
      case 1:
        day = "Monday";
        break;
      case 2:
         day = "Tuesday";
        break;
      case 3:
        day = "Wednesday";
        break;
      case 4:
        day = "Thursday";
        break;
      case 5:
        day = "Friday";
        break;
      case 6:
        day = "Saturday";
    }
    if (month === 0) {
      month = 'Jan'
    } else if (month === 1) {
      month = 'Feb'
    } else if (month === 2) {
      month = 'March'
    } else if (month === 3) {
      month = 'April'
    } else if (month === 4) {
      month = 'May'
    } else if (month === 5) {
      month = 'Jun'
    } else if (month === 6) {
      month = 'Jul'
    } else if (month === 7) {
      month = 'Aug'
    } else if (month === 8) {
      month = 'Sep'
    } else if (month === 9) {
      month = 'Oct'
    } else if (month === 10) {
      month = 'Nov'
    } else if (month === 11) {
      month = 'Dec'
    }
    let Header = day
    let SQLFormat = baseDate.toISOString().slice(0,10)
    return {Header,SQLFormat,index}
  }

  downloadCSV = data => {
    if (data === 'prod') {
      if (this.state.type !== 'Dry') {
        window.open(address+'/item/export/wet?startdate='+this.state.date[0].SQLFormat+'&numofdays='+this.state.numofdays+'&type='+this.state.type)
      } else {
        window.open(address+'/item/export?startdate='+this.state.date[0].SQLFormat+'&numofdays='+this.state.numofdays+'&type='+this.state.type)
      }
    } else {
    window.open(address+'/item/export/bagging?startdate='+this.state.date[0].SQLFormat+'&numofdays='+this.state.numofdays+'&type='+this.state.type)
    }
  }

  updateNOD = async (value,date) => {
    
    this.setState({numofdays:value})
    return 'FINISHED'
  }

  render() {
    return <>
    <Layout style={{ minHeight: '100vh' }}>
    <Header passDate={this.updateDate} download={this.downloadCSV} NOD={this.state.numofdays} NAVSync={this.userSynced} passType={this.updateType} passTab={this.updateUnscheduledStandard} passNOD={this.updateNOD}/>
    <Layout>

    <Layout className = 'site-layout'>
    <Content style={{ margin: '0 16px' }}>
    <div className="site-layout-background" style={{ padding: 0, minHeight: 360,}}>
    <Dashboard date={this.state.date} type={this.state.type} DownloadCSV={this.state.download} synced={this.state.synced} numofdays={this.state.numofdays} tab={this.state.tab}/>
    </div>
    </Content>
    <FooterComponent/>
    </Layout>
    </Layout>
    </Layout>
    </>
  }
}




export default HomePage;