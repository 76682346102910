import React from 'react';
import axios from 'axios';
import './mainpage.style.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import { Icon, Label, Input } from "semantic-ui-react";
import 'primereact/resources/primereact.css';
import SearchFilters from '.././searchbar/classicsearch.component'
import address from '../address.component'
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Calendar } from "primereact/calendar";
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
let id = [0,'nothing']
let insertlocation
let editing = false
function handleDragOver(e) {
  e.preventDefault();
  e.stopPropagation();
}

class Card extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      tags: [],
    };
    this.tags = [];
  }

  componentDidMount() {
    //this.setState({ tags: [] }, () => this.TagJob());
  }

  render() {
    return (
      <>
    
        <div
          className="job-card"
          draggable={true}
          onDragEnter={(ev) =>{
            editing=true
            insertlocation = this.props.id}}
          onDrag={e => {
            id = {OriginIndex:this.props.id, OriginColumn:this.props.originColumn}
          }}
          onDragEnd={()=>editing=false}
          onClick={() => console.log('clicked ',this)}
        >
          <p style={{marginBottom:this.props.originColumn === 'unscheduled' ?0 : '.25rem',fontWeight:'600'}}>MO-{this.props.data.order_no ? this.props.data.order_no: 'N/A'}({this.props.data.item_no ? this.props.data.item_no: 'N/A'})</p>
          <p style={{marginBottom:this.props.originColumn === 'unscheduled' ?0 : '.25rem'}}>{this.props.data.description ? this.props.data.description: 'Missing Description'}</p>
          <p>QTY:{this.props.data.quantity ? this.props.data.quantity: '0'}({this.props.data.uom ? this.props.data.uom: 'N/A'}) Due {this.props.data.due_date ? this.props.data.due_date.slice(0,10) : 'N/A'}</p>
          </div>
      </>
    );
  }
}

class SpecialCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      tags: [],
    };
    this.tags = [];
  }

  render() {
    return (
      <>
    
        <div
          className="job-card"
          style={{backgroundColor:'gold'}}
          draggable={true}
          onDragEnter={(ev) =>{
            insertlocation = this.props.id}}
          onDrag={e => {
            id = {OriginIndex:this.props.id, OriginColumn:this.props.originColumn}
          }}
          onDragStart={()=>{
            editing=true
            console.log('start!')
          }}
          onDragEnd={()=>{
            editing=false
            console.log('end!')
            }}
          onClick={() => console.log('clicked ',this)}
        >
          <p className={'item'} style={{fontSize:'1.75em',textAlign:'center'}}>{this.props.data.description ? this.props.data.description: 'N/A'}</p>            
          </div>
      </>
    );
  }
}

class Column extends React.Component {
  constructor(props) {
    super(props);
    this.joblistings = [];
    this.jobholder = [];
    this.state = {
      data: [],
    };
  }

  handleDrop(e, i) {
    if (id.OriginColumn !== "nothing") {
      e.preventDefault();
      e.stopPropagation();
      this.props.parentCallback(Object.assign({TargetIndex:insertlocation,TargetColumn:this.props.varname,method:'drop'},id));
    } else {
      console.log("Cant move to Nothing");
    }
    id = {OriginIndex:-2,OriginColumn:"nothing"};
  }

  handleGrandParentCallBack = (columnData) => {
    this.props.GrandChildCallback(columnData);
  };


  JobTagColors(name) {
    if (name === "programming") {
      return "#bdd3d7";
    } else if (name === "queue") {
      return "#8d9498";
    }
  }

  render() {

    return (
      <>
        <div
        className={'ColumnContainer'}

        >
          <div>
            <h3 style={{ textAlign: "center", margin: 0 }}>
              {this.props.name}
            </h3>
            <h6
              style={{
                textAlign: "right",
                margin: "0",
                marginTop: "0%",
                marginRight: "1vw",
              }}
            >
              {/**this.props.data.length}/{this.props.total*/}
            </h6>
          </div>
              {/**SEPARATOR */}
          <div
            className="Column"
            style={{width:this.props.NOD === 5 ? '16.4vw' : this.props.NOD === 6 ? '13.3vw': '11.3vw'}}
            id={'Column#'+this.props.varname}
            onDrop={(e) => this.handleDrop(e)}
            onDragOver={(e) => handleDragOver(e)}
            onScroll={this.handleScroll}
          >
          {this.props.data.map((x,index)=> {return x.item_no !== 'FILLER' ?
          <Card
            GrandparentCallback={this.handleGrandParentCallBack}
            key={this.props.name + index}
            id={index}
            data={this.props.data[index]}
            originColumn={this.props.varname}
          />
          :
          <SpecialCard
            GrandparentCallback={this.handleGrandParentCallBack}
            key={this.props.name + index}
            id={index}
            data={this.props.data[index]}
            originColumn={this.props.varname}
          />
            })}
            <div
              className="placeholderJob"
              onDragOver={() => (insertlocation = this.props.data.length)}
              style={{
                border: "4px solid transparent",
                boxShadow: "0,0,0 rgb(0,0,0 /2%)",
                marginBottom: "4vh",
              }}
            ></div>
          </div>
        
        </div>
      </>
    );
  }
}

class Unscheduled extends React.Component { // Column Of Unscheduled

  constructor(props) {
    super(props)
    this.state = {
      data: [],
      selected: 0,
      programs: [],
      program1: [],
      program2: [],
      chosenprogram: -1,
      visible:false,
      search:{
          inputValue:'',
          selectedSearchables: [],
      },
      filter:{ name: 'MOddd', code: 'MO' },
      filters:[
        { name: 'Due Date', code: 'DueDate' },
        { name: 'MO', code: 'MO' }
      ],
    }

    console.log(this.props)
  }

  handleScroll = (e) => { // useless function here incase if we need it.
    if (Math.floor(e.target.scrollHeight - e.target.scrollTop) <= Math.floor(e.target.clientHeight)) {
      //this.props.callback()
    }

  }

  handleDate(data) {
    console.log(this.calendar)
    console.log(data)
    this.setState({date:data})
    if (data[1] === null ) {
      // NOTHING ELSE
    } else {
      this.props.passUnscheduledDate(data) // PASS DATA
    }
  }

  displayModal = data => { // displays alternate popup (split programs/job)
    console.log('hello???',data)
    this.setState({ data: data }, () => this.setState({ visible: true }))
  }

  handleGrandParentCallBack = (columnData) =>{ //Job Drag Callback
   this.props.GrandChildCallback(columnData)
}

handleDrop(e,i) { // Handle Drop, when object from right columns are dropped on unscheduled
  if (id.OriginColumn !== "nothing") {
    e.preventDefault();
    e.stopPropagation();
    this.props.parentCallback(Object.assign({TargetIndex:insertlocation,TargetColumn:this.props.varname,method:'unschedule'},id));
  } else {
    console.log("Cant move to Nothing");
  }
  id = {OriginIndex:-2,OriginColumn:"nothing"};
}

  render() {
    return <>
<div className="ColumnContainer" style={{display:'inline-block',float:'left',border:'4px solid #dcdcdc',backgroundColor:'#dcdcdc',borderTopLeftRadius:'.6rem',borderRight:'0px solid transparent'}}>
    <div>
      <h1 style={{ textAlign: 'center',margin:0}}>{this.props.name}</h1>
      <div style={{backgroundColor:'white',margin:0,width:'99%',borderRadius:'.3rem'}}>
        <SearchFilters
        placeholder="Search..."
        style={{width:'100%'}}
        searchData={this.state.search}
        setSearchData={(data)=>this.props.searchCallback(data)}
        setInputFocus={data=>console.log(data)}
        inputFocus={true}
        disabled={this.props.varname === 'unscheduled' ? false: true}
        />
      </div>
      <div style={{width:'99%',marginTop:'-2%',borderRadius:'.3rem',overflow:'visible',clear:'right'}}>
        <Calendar style={{float:'left',width:'60%'}}className='MINICalendar'id="range" ref={e=>this.calendar = e} value={this.state.date} onChange={(e) => this.handleDate(e.value)} placeholder={'Select Date Range'} selectionMode="range" readOnlyInput />
        <div style={{backgroundColor:'white',width:'35%',float:'left',marginLeft:'5%',height:'2em',fontSize:'1.25em',lineHeight:'2em',textAlign:'center',borderRadius:'.3em'}}
        onClick={()=>{
          this.setState({date:null})
          this.props.clearUnscheduledDate()
        }}
        > Clear Date </div>
      </div>
    </div>
    
        <div style={{marginTop:'.5vh',textAlign:'center',fontSize:'1vw'}}>
        <div style={{float:'right',marginRight:'1vw'}}>
         Due Date
          </div> 
        <InputSwitch checked={this.state.checked} onChange={(e) => this.setState({checked:e.value},()=>this.props.FilterCallback(e.value))} className={'SORTBYSLIDER'}/>
          <div style={{float:'right'}}>Sort By: MO </div> 

    
        </div>
          
      <div id='unscheduled' className="Column" onDrop={e=>this.handleDrop(e)} onDragOver={e => handleDragOver(e)} style={{height:'77.5vh',overflowX:'hidden',direction:'rtl',backgroundColor:'#dcdcdc', width:this.props.NOD === 5 ? '16.4vw' : this.props.NOD === 6 ? '16.4vw': '16.4vw'}} onScroll={this.handleScroll}>
        {this.props.data.map((x,index)=> {return x.item_no !== 'FILLER' ?
          <Card
            GrandparentCallback={this.handleGrandParentCallBack}
            key={this.props.name + index}
            id={index}
            data={this.props.data[index]}
            originColumn={this.props.varname}
          />
          :
          <SpecialCard
            GrandparentCallback={this.handleGrandParentCallBack}
            key={this.props.name + index}
            id={index}
            data={this.props.data[index]}
            originColumn={this.props.varname}
          />
        })}
        {this.props.varname === 'standard' ?
        <div
        className="placeholderJob"
        onClick={()=>this.props.makeStandard()}
        style={{
          border: "4px solid transparent",
          boxShadow: "0,0,0 rgb(0,0,0 /2%)",
          height:'3vh',
          lineHeight:'0vh',
          marginBottom: "4vh",
          fontSize:'2em',
          textAlign:'center',
        }}
      > + </div>
    :
    <div
    className="placeholderJob"
    style={{
      border: "4px solid transparent",
      boxShadow: "0,0,0 rgb(0,0,0 /2%)",
      height:'3vh',
      lineHeight:'0vh',
      marginBottom: "4vh",
      fontSize:'2em',
      textAlign:'center',
      backgroundColor:'#dcdcdc'
    }}
  ></div>
    }
      </div>

</div>
    </>
  }
}

class Page extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      data:[
      [],[],[],[],[],[],[],
      ],
      SelectedDate:new Date(),
      unscheduled:[],
      standard:[],
      search:{
        inputValue:'',
        selectedSearchables: [],
      },
      desc:'',
      editing:false,
      keyword:'',
      dateFilter:null,
      }
    console.log(this.props)
  }

  download(csv,filename) {
    const a = document.createElement('a')
    a.href = encodeURI(csv)
    a.download = filename+'.csv'
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }
  
  GenerateCSV() {
    let csv = 'data:text/csv;charset=utf-8,'
    let maxLength = 0
    for (let i = 0; i < this.props.date.length; i++) {
      if (this.state.data[i].length > maxLength) {
        maxLength = this.state.data[i].length
      }
    }
    console.log(maxLength)
    for (let i = 0; i < this.props.date.length;i++) {
      csv += this.props.date[i].Header + ','
    }
    csv += '\n'
    for (let i = 0; i < maxLength;i++) {
      for (let z = 0; z < this.props.date.length;z++) {
        if (this.state.data[z][i]) {
          let item = this.state.data[z][i]
          console.log(item)
          csv = csv + JSON.stringify('MO' + item.order_no + ' Item No.' + item.item_no + ' QTY ' + item.quantity + ' Missing Time') + ','
        } else {
          csv = csv + ','
        }
      }
      csv+= '\n'
      console.log(csv)
    }
    console.log(this.props.date)
    this.download(csv,this.props.date[0].SQLFormat + 'to' + this.props.date[this.props.date.length-1].SQLFormat+'.csv')
  }

  componentDidUpdate(prevProps) {
    if (this.props.DownloadCSV !== prevProps.DownloadCSV) {
      this.GenerateCSV()
    }
    if (JSON.stringify(this.props.date) !== JSON.stringify(prevProps.date) || this.props.type !== prevProps.type){
      this.UpdateScheduled()
    }
    if (this.props.type !== prevProps.type || this.props.synced !== prevProps.synced) {
      this.dumbLazyUnscheduledCall()
    }
  }

  UpdateScheduled() {
    if (!this.props.date[0]) {
      return
    }
    axios.get(address+'/item',{
      params:{
        startdate:this.props.date[0].SQLFormat,        
        numofdays:this.props.numofdays,
        type:this.props.type
        
      }
    }).then(res=>{
      const data = res.data
      this.setState({data:data})
    })
  }
  updateStandard() {
    axios.get(address+'/item/sb/template').then(res=> {
      const data = res.data
      this.setState({standard:data})
    })
  }

  updateUnscheduled(startdate,enddate) {
    let params = {
      type:this.props.type,
      startdate,
      enddate,
    }
    if (this.state.filter === true) {
      params.sortby = 'DueDate'
    } else {
      params.sortby = 'MO'
    }
    axios.get(address+'/item?status=unscheduled',{
      params
    }).then(res=>{
      const data = res.data
      this.setState({unscheduled:data})
    })
  }
  dumbLazyUnscheduledCall() {
    if (this.state.keyword.length > 0 || this.state.dateFilter !== null) {
      this.searchFunction()
    } else {
      this.updateUnscheduled()
    }
  }
  MasterUpdate() {
    console.log('UPDATING!')
    if (editing !== true) {
      this.UpdateScheduled()
      this.dumbLazyUnscheduledCall()
      this.updateStandard()
    } else {
      console.log("CANT DO THAT USER IS EDITING")
    }
  }

  componentDidMount() {
    this.MasterUpdate()
    this.INTERVALID = setInterval(()=>this.MasterUpdate(),300000)
  }

  componentWillUnmount() {
    clearInterval(this.INTERVALID)
  }

  handleColumnCallback = async data => {
    console.log(data)
    let MasterCopy = JSON.parse(JSON.stringify(this.state.data))
    let Unscheduled = []
    let Standard = []
    if (data.OriginColumn === 'unscheduled' || data.TargetColumn === 'unscheduled') {
      Unscheduled = JSON.parse(JSON.stringify(this.state.unscheduled))
    }
    if (data.OriginColumn === 'standard' || data.TargetColumn === 'standard') {
      console.log('standard')
      Standard = JSON.parse(JSON.stringify(this.state.standard))
    }
    let newColumn = data.TargetColumn !== 'unscheduled' ? data.TargetColumn !== 'standard' ? JSON.parse(JSON.stringify(MasterCopy[data.TargetColumn])) : Standard : Unscheduled
    let oldColumn = data.OriginColumn !== 'unscheduled' ? data.OriginColumn !== 'standard' ? JSON.parse(JSON.stringify(MasterCopy[data.OriginColumn])) : Standard : Unscheduled
    let spliceBackup = JSON.parse(JSON.stringify(oldColumn));
    let single = false
      if (data.TargetColumn !== data.OriginColumn) { // columns are different!
        oldColumn.splice(data.OriginIndex, 1);
        newColumn.splice(insertlocation, 0, spliceBackup[data.OriginIndex]);
        if (data.OriginColumn !== 'standard' && data.OriginColumn !== 'unscheduled') {
          MasterCopy[data.OriginColumn] = oldColumn
        }
      } else { // Columns are the same!
        single = true
        newColumn.splice(data.OriginIndex, 1);
        newColumn.splice(insertlocation, 0, spliceBackup[data.OriginIndex]);
      }
    
    let calls = []
    if (single === true) {
      if (data.OriginColumn === 'unscheduled') {
        this.dumbLazyUnscheduledCall()
        return 'Done'
      } else if (data.OriginColumn !== 'standard'){
        MasterCopy[data.OriginColumn] = newColumn
        calls.push({
          data:this.processColumn(newColumn),
          date:this.props.date[data.OriginColumn].SQLFormat,
          type:this.props.type,
          index:data.OriginColumn
        })
      }
    } else {
      if (data.TargetColumn === 'unscheduled') { // ALSO CALL SINGLE API
        //just empty intentionally.
      } else if (data.TargetColumn === 'standard') {
        console.log(spliceBackup[data.OriginIndex])
        if (spliceBackup[data.OriginIndex].item_no === 'FILLER') {
          //TODO CALL DELETE
        } else { //TODO ADD TOAST?
          this.toast.show({severity:'error', summary: 'Error : Action Rejected', life: 3000});
          return 'ERROR~ CANT PUT ITEM THERE'
        }
      } else {
        calls.push({
          data:this.processColumn(newColumn),
          date:this.props.date[data.TargetColumn].SQLFormat,
          type:this.props.type,
          index:data.TargetColumn
        })
        MasterCopy[data.TargetColumn] = newColumn
      }
      if (data.OriginColumn === 'unscheduled') { // ALSO CALL SINGLE API
        this.setState({unscheduled:oldColumn})
      } else if (data.OriginColumn !== 'standard') {
        calls.push({
          data:this.processColumn(oldColumn),
          date:this.props.date[data.OriginColumn].SQLFormat,
          type:this.props.type,
          index:data.OriginColumn
        })
        MasterCopy[data.OriginColumn] = oldColumn
      }  
    }

    await this.updateCall(calls)
    if (data.TargetColumn === 'unscheduled') { // ALSO CALL SINGLE API
      if (spliceBackup[data.OriginIndex].item_no === 'FILLER') {
        //TODO CALL DELETE
      } else {
        this.dumbLazyUnscheduledCall()
      }
    }
    return 'done'
  }

  processColumn(Column) {
    let ids = []
    for (let i = 0; i < Column.length;i++) {
      ids.push(Column[i].id)
    }
    return ids
  }

  async updateCall(columns) {
    await axios.put(address+'/item', {
      columns
    }).then(res=>{
      const data = res.data
      console.log(data,columns)
      let copy = JSON.parse(JSON.stringify(this.state.data))
      for (let i = 0; i < columns.length;i++) {
        copy[columns[i].index] = data[i]
      }
      this.setState({data:copy})
      return 'finished'
    })
  }

  makeStandard() {
    console.log('hello!')
    let desc = this.state.desc
    if (this.state.calling === true) {
      this.toast.show({severity:'error', summary: 'Error : Don\'t spam the button!', life: 3000});
      return
    }
    if (desc.length === 0) {
      this.toast.show({severity:'error', summary: 'Error : Please Enter a Description', life: 3000});
      return // TODO ADD TOAST
    } else { //call API
      this.setState({calling:true})
      axios.post(address+'/item/sb/template', {
        description:desc
      }).then(res=>{
        this.setState({calling:false})
        this.setState({visible:false})
        this.setState({desc:''})
        this.updateStandard()
      })
    }
  }

  search = data => {
    this.setState({keyword:data.inputValue},()=>this.searchFunction())
  }
  
  UnscheduledDate = data => {
    this.setState({dateFilter:data},()=>this.searchFunction())
  }



  searchFunction() {
    axios.get(address+'/item/search',{params:{
      keyword:this.state.keyword,
      startdate:this.state.dateFilter ? this.state.dateFilter[0].toISOString().slice(0,10) : null,
      enddate:this.state.dateFilter ? this.state.dateFilter[1].toISOString().slice(0,10) : null,
      type:this.props.type,
      sortby:this.state.filter === true ? 'DueDate' : 'MO'
    }}).then(res=>{
      const data = res.data
      console.log(data)
      this.setState({unscheduled:data})
    })
  }

  filter = data => {
    this.setState({filter:data},()=>this.dumbLazyUnscheduledCall())
  }

  render() {
    return <>
     <Toast ref={(el) => this.toast = el} position="top-center"/>     
    <Unscheduled 
    data={this.props.tab === 'Unscheduled' ? this.state.unscheduled : this.state.standard}
    name={this.props.tab === 'Unscheduled' ? 'Unscheduled' : 'Standard Blocks'}
    parentCallback={this.handleColumnCallback}
    varname={this.props.tab === 'Unscheduled' ? 'unscheduled' : 'standard'}
    makeStandard={()=>this.setState({visible:true})}
    searchCallback={this.search}
    passUnscheduledDate={this.UnscheduledDate}
    clearUnscheduledDate={()=>this.setState({dateFilter:null},()=>this.MasterUpdate())}
    FilterCallback={this.filter}
    NOD={this.props.numofdays}
    />
    <div style={{whiteSpace:'nowrap',touchAction:'none !important',backgroundColor:'white',overflow:'auto',overflowX:this.props.date.length === 5 ? 'hidden':''}}>
    {this.props.date.map((x,index)=> {return <>
        <Column
        name={x.Header}
        key={"Column"+x.Header}
        parentCallback={this.handleColumnCallback}
        data={this.state.data[index] ? this.state.data[index] : []}
        varname={index}
        NOD={this.props.numofdays}
        />
      </>
      })}
    </div>
      <Dialog
      visible={this.state.visible}
      style={{width:'30vw',height:'25vh',backgroundColor:'white',zIndex:60}}
      modal={true}
      onHide={e => this.setState({ visible: false })}
      draggable={false}
      onEscape={true}
      contentStyle={{height:'100%',padding:'0px'}}
      >
        <InputText value={this.state.desc} placeholder={'Enter Description'} onChange={(e) => this.setState({desc:e.target.value})} style={{width:'80%',fontSize:'2em',marginLeft:'10%'}} />
        <h2 onClick={()=>this.makeStandard()}style={{textAlign:'center',width:'55%',height:'5vh',backgroundColor:'blue',color:'white',lineHeight:'5vh',marginTop:'5%',marginLeft:'22.5%'}}> Create Standard Block</h2>
      </Dialog>
    </>
  }
}

export default Page
