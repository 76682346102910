import React from 'react';
import { NavDropdown,Navbar,Nav,NavLink } from 'react-bootstrap';
import { Avatar } from 'antd';
import logo from '../../assets/embassy_logo.png';
import { connect } from 'react-redux';
import infoCircle from '../../assets/infocircle.svg';
import bellfill from '../../assets/bellfill.svg';
import fullscreen from '../../assets/fullscreen.svg';
import { Calendar } from "primereact/calendar";
import { Dropdown } from 'primereact/dropdown';
import { store } from '../../redux2/store';
import { EMB_LOG_OUT } from '../../redux2/types';
import axios from 'axios';
import address from '../address.component'
import { Dialog } from 'primereact/dialog';
import './header.styles.css'
function LogOutEMB() {
  store.dispatch({
    type:EMB_LOG_OUT,
  })
}



/*
    <NavDropdown title="Temporarily Useless" id="basic-nav-dropdown">
        <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
        <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
        <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
    </NavDropdown>
*/
let csv = 'hello,there'
csv = 'data:text/csv;charset=utf-8,' + csv;
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date:[new Date(),new Date((new Date()).setDate(new Date().getDate()+6))],
      types:{ name: 'Flavour', code: 'Flavour' },
      NOD:{name:'Weekdays',code:5},
      type:[],
      tabs:[],
      NumberOfDays:[],
    }
    console.log(props)
  }
  
  handleDate(data) {
    console.log(this.calendar.state.viewDate)
    let Back = 1 - data[0].getDay()
    let Forward = this.props.NOD - data[0].getDay()
    console.log(Back,Forward,this.props.NOD,data[0])
    let StartDate = new Date(data[0])
    let EndDate = new Date(data[0])
    StartDate = new Date(StartDate.setDate(data[0].getDate()+Back))
    EndDate = new Date(EndDate.setDate(data[0].getDate() + Forward))
    this.setState({date:[new Date(StartDate),new Date(EndDate)]},()=>{
      this.calendar.updateInputfield(this.state.date)
      this.props.passDate(this.state.date)
    })
  }

  componentDidMount() {
    this.handleDate([this.state.date[0]])
    this.getDropdown('tabs')
    this.getDropdown('NumberOfDays')
    this.getDropdown('type')
    this.GetMostRecentSync()
    setInterval(()=>this.GetMostRecentSync(),150000)
  }

  GetMostRecentSync() {
    axios.get(address+'/sync').then(res=> {
      const data = res.data
      const date = new Date(data.utcdatetime)
      this.setState({latestSync:date.toLocaleDateString() + ' ' + date.toLocaleTimeString()})
      this.setState({latestSyncTime:date.toLocaleTimeString()})
      this.setState({NAVCompany:data.nav_company_name})
    })
  }

  getDropdown(dropdown) {
    axios.get(address+'/config/dropdown',{params:{dropdown}}).then(res=> {
      const data = res.data
      this.setState({[`${dropdown}`]:data},()=>console.log(this.state[dropdown]))
    })
  }

  async handleNOD(value) {
    let x = await this.props.passNOD(Number(value))
    console.log(this.state.date[0])
    this.handleDate([this.state.date[0]])
  }

  render() {
    return <Navbar bg="light" expand="lg">

    <Navbar.Brand href={'/'}>
        <img
            alt=""
            src={logo}
            width="180"
            height="45"
            className="d-inline-block align-top"
        />
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Calendar ref = {e=>this.calendar = e} id="range" value={this.state.date} onChange={(e) => this.handleDate(e.value)} placeholder={'Select Date Range'} selectionMode="range" readOnlyInput />
          <Dropdown value={this.state.types} options={this.state.type} onChange={e=>this.setState({types:e.value},()=>this.props.passType(e.value))} optionLabel="name" placeholder="Select MO Type" style={{ textAlign: 'center',marginLeft:'1vw',fontSize:'6em'}}/>
          <Dropdown value={this.state.tab} options={this.state.tabs} onChange={e=>this.setState({tab:e.value},()=>this.props.passTab(e.value))} optionLabel="name" placeholder="Unscheduled" style={{ textAlign: 'center',marginLeft:'1vw',fontSize:'6em'}}/>
          <Dropdown value={this.state.NOD} options={this.state.NumberOfDays} onChange={e=>this.setState({NOD:e.value},()=>this.handleNOD(e.value.code))} optionLabel="name" placeholder="Weekdays" style={{ textAlign: 'center',marginLeft:'1vw',fontSize:'6em'}}/>
          <Nav.Link onClick={()=>this.props.download('prod')} style={{marginLeft:'.5vw'}}>Download Production Schedule</Nav.Link>
          <Nav.Link onClick={()=>this.props.download('bag')} style={{marginLeft:'.5vw'}}>Download Bagging Record</Nav.Link>
        </Nav>
        <h6 style={{marginRight:'1vw',marginTop:'1vh'}}> 
            Last Sync {this.state.latestSyncTime}
          </h6>
        {this.props.embLoggedIn ?
            <h4 
            style={{
              marginRight:'1vw'
            }}
            onClick={()=>LogOutEMB()}
            >
              Log Out
            </h4>
            : ("")}
        <i class="pi pi-cog" style={{fontSize:'1.25vw'}} onClick={()=>this.setState({visible:true},()=>this.GetMostRecentSync())}></i>

    </Navbar.Collapse>
    <Dialog
      visible={this.state.visible}
      style={{width:'30vw',height:'25vh',backgroundColor:'white',zIndex:60}}
      modal={true}
      onHide={e => this.setState({ visible: false })}
      draggable={false}
      onEscape={true}
      className={'HEADERPOPUP'}
      contentStyle={{height:'100%',padding:'0px'}}
      >
        <h1 style={{textAlign:'center'}}>
          Last Sync
          </h1>
        <h2 style={{textAlign:'center'}}>
            {this.state.latestSync}
        </h2>

        <h1 style={{textAlign:'center'}}>
          NAV Company
          </h1>
        <h2 style={{textAlign:'center'}}>
        {this.state.NAVCompany}
        </h2>


      </Dialog>
</Navbar>
  }
}

const mapStateToProps = state => ({
    embLoggedIn: state.embLoggedIn
})
export default connect(mapStateToProps)(Header);