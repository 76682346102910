import React from 'react'
import {Link} from 'react-router-dom';
import { Layout} from 'antd';
const { Footer } = Layout;
const FooterComponent = () => (
    <Footer  style={{ textAlign: 'center', height:40 }} >
        <strong>Copyright &copy; 2021 <Link to="/"> Alpaca Systems </Link></strong>All rights reserved.
        <div>
        <b>Version</b> 1.2
        </div>
    </Footer>
);



export default FooterComponent;